
export function parseLine(line) {
  const count = getAmount(line);
  if (count === null) {
    return null;
  }
  const versionsRes = getVersions(count, line);
  const commentRes = getComment(line);

  if (versionsRes.match) {
    line = line.replace(versionsRes.match, '');
  }
  if (commentRes.match) {
    line = line.replace(commentRes.match, '');
  }

  const match = line.match(/(\d+)/);

  const cardName = line.replace(match[0], '').trim();

  return {
    count: count,
    cardName: cardName,
    versions: versionsRes.versions,
    comment: commentRes.comment,
  };
}

function getAmount(line) {
  const regex = /^\d+/g;
  const match = line.match(regex);
  if (match) {
    return parseInt(match[0]);
  }
  return null;
}


function getVersions(defaultAmount, line) {
  const regex = /[vV]\{[^{}]*\}/g;
  
  const matchs = line.match(regex);

  // only first appareance of version
  if (matchs && matchs.length > 0) {
    const match = matchs[0];
    const versions = match.substring(2, match.length - 1).split(',').reduce((acc,version) => {
      const versionParts = version.trim().split(':');
      const key = versionParts[0];
      acc = {
        ...acc,
        [key]: parseInt(versionParts[1] ? versionParts[1] : defaultAmount),
      }
      return acc;
    }, new Map());
    return {
      match: match,
      versions: versions,
    }
  }
  return {
    match: null,
    versions: {},
  }
}


function getComment(line) {
  const regex = /[cC]\{[^{}]*\}/g;
  
  const matchs = line.match(regex);

  // only first appareance of comment
  if (matchs && matchs.length > 0) {
    const match = matchs[0];
    const comment = match.substring(2, match.length - 1);
    return {
      match: match,
      comment: comment,
    }
  }
  return {
    match: null,
    comment: null,
  }
}

export function recomposeLine(card) {
    let line = '';

    line += card.count + ' ';
    line += card.cardName;
    if (card.versions !== undefined && Object.keys(card.versions).length > 0) {
      line += ' V{';
      const versions = [];
      for (const [key, value] of Object.entries(card.versions)) {
        if (value === card.count) {
          versions.push(key);
        } else {
          versions.push(key + ':' + value);
        }
      }
      line += versions.join(', ');
      line += '}';
    }

    if (card.comment !== undefined && card.comment !== null && card.comment !== '') {
      line += ' C{' + card.comment + '}';
    }
    return line;
}


/*module.exports = {
  parseLine,
  recomposeLine
};*/
