// utils/deckParser.js
import { parseLine } from './lineParse';
import { isMtgdeck,  parseMtgdeck} from './integrations';
import { fetchCardDetails } from '../services/cardService';



const sortByCMC = (a, b) => {
  if (a.isLand && !b.isLand) {
    return 1;
  } else if (!a.isLand && b.isLand) {
    return -1;
  }
  
  if (a.totalManaCost > b.totalManaCost) {
    return 1;
  }
  if (a.totalManaCost < b.totalManaCost) {
    return -1;
  }
  return 0;
};

export async function parseDeck(setDeckContent, deckText) {

  if (isMtgdeck(deckText)) {
    deckText = await parseMtgdeck(setDeckContent, deckText)
  } 
  
  const mainDeck = [];
  const sideboard = [];

  // { count: 4, name: 'Forest', versions: { 'M19': 4 }, comment: null }
  const unique = new Map();


  const lines = deckText.trim().split(/\r?\n/);
  let isSideboard = false;



  const splitPatterm = '#-#-#-#';

  for (const fullLine of lines) {
    if (fullLine.trim() === '') {
      isSideboard = true;
      continue;
    }

    const card = parseLine(fullLine);

    if (!card) {
      continue;
    }


    const key = (isSideboard ? 'sideboard' : 'mainDeck') + splitPatterm + card.cardName;


    if (unique.has(key)) {
      unique.set(key, {
        ...card,
        count: unique.get(key).count + card.count,
      });
    } else {
      unique.set(key, card);
    }
  }

  // iterate over unique
  for (const [key, card] of unique.entries()) {
    const cardDetails = await fetchCardDetails(card.cardName);
    const [deckType] = key.split(splitPatterm);
    if (deckType === 'mainDeck') {
      mainDeck.push({...card, ...cardDetails});
    } else {
      sideboard.push({...card, ...cardDetails});
    }
  }

  return { mainDeck: await populateDetails(mainDeck), sideboard: await populateDetails(sideboard) };
}


export async function populateDetails(deck) {
  const populated = await Promise.all( deck.map(async (card) => {
    const cardDetails = await fetchCardDetails(card.cardName);
    return  {...card, ...cardDetails};
  }))

  populated.sort(sortByCMC)
  return populated
}


const formats = [
  'standard',
  'modern',
  'pioneer'
]

export function getDeckLegality(cards) {
  const notLegal = new Map()
  for (const format of formats) {
    notLegal.set(format, [])
  }
  for (const card of cards) {
    for (const format of formats) {
      if (card.legalities[format] !== 'legal') {
        const currentNotLegal = notLegal.get(format)
        notLegal.set(format, [...currentNotLegal, card.cardName])
      }
    }
  }
  return notLegal;
}