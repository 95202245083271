// components/ShareDCIButton.js
import React from 'react';
import styles from './ShareDCIButton.module.css';

const ShareDCIButton = ({ isShared, handleShare, deckText, version }) => {
  const handleClick = async () => {
    await handleShare(version, deckText);
  };

  return (
    <button onClick={handleClick} className={styles.shareButton}>
      {'DCI list'}
    </button>
  );
};

export default ShareDCIButton;
