// MagicApp.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DeckInput from "./components/DeckInput";
import back_side_image from './assets/back_side.bmp';
import DeckDisplay from "./components/DeckDisplay";
import CardInput from "./components/CardInput";
import ShareButton from "./components/ShareButton";
import ShareDCIButton from "./components/ShareDCIButton";
import DeckNameInput from "./components/DeckNameInput";
import Legalities from "./components/Legalities";
import ErrorAlert from "./components/ErrorAlert";
import { defaultVersion, getAllVersions, getAmount } from "./utils/versions";
import { parseDeck, populateDetails, getDeckLegality } from "./utils/deckParser";
import {recomposeLine} from "./utils/lineParse";
import "./App.css";
import { backendUrl } from "./config";



function deckToText(mainDeck, sideboard) {
  const mainDeckText = mainDeck.map(recomposeLine).join("\n");
  const sideboardText = sideboard.map(recomposeLine).join("\n");
  return mainDeckText + "\n\n" + sideboardText;
}

function MagicApp() {
  const [mainDeck, setMainDeck] = useState([]);
  const [sideboard, setSideboard] = useState([]);
  const [legalities, setLegalities] = useState(new Map());
  const [selectedCard, setSelectedCard] = useState(null);
  const [error, setError] = useState(null);
  const [isShared, setIsShared] = useState(false);
  const [deckText, setDeckText] = useState("");
  const [version, setVersion] = useState(defaultVersion);
  const [versions, setVersions] = useState([]);
  const [deckName, setDeckName] = useState(null)
  const navigate = useNavigate();
  const { id } = useParams();

  const handleInput = async (newDeckText) => {
    setDeckText(newDeckText);
    const { mainDeck, sideboard } = await parseDeck(setDeckText, newDeckText);
    setMainDeck(mainDeck);
    setSideboard(sideboard);
    setVersions(getAllVersions([...mainDeck, ...sideboard]) || []);
    setLegalities(getDeckLegality([...mainDeck, ...sideboard]))
  };

  const buildQueryParam = (version, deckList) => {
   return encodeURIComponent(deckList.reduce((acc, card) => {
      return acc += `${getAmount(card, version)} ${card.cardName}\n`
   }, ''))
  };

  const handleShare = useCallback(
    async (textareaContent) => {
      try {
        const { mainDeck, sideboard } = await parseDeck(setDeckText, textareaContent);
        const response = await axios.post(`${backendUrl}/deck`, {
          id: deckName,
          mainDeck,
          sideboard,
        });
        const deckId = response.data.id;
        navigate(`/magic/${deckId}`);
        setIsShared(true);
        setTimeout(() => {
          setIsShared(false);
        }, 2000);
      } catch (err) {
        setError("Error al compartir el mazo");
      }
    },
    [navigate, deckName]
  );

  const handleDCIList = useCallback(
    async (version, textareaContent) => {
      const { mainDeck, sideboard } = await parseDeck(setDeckText, textareaContent);
      const externalUrl = `https://www.decklist.org/?deckmain=${buildQueryParam(version, mainDeck)}&deckside=${buildQueryParam(version, sideboard)}`;
      window.open(externalUrl, '_blank'); // Abre la URL en una nueva pestaña o ventana
    },
    []
  );

  useEffect(() => {
    if (id) {
      setDeckName(id);
      const fetchDeck = async () => {
        try {
          const response = await axios.get(`${backendUrl}/deck/${id}`);
          const deckContent = response.data;

          const main = await populateDetails(deckContent.mainDeck);
          const side = await populateDetails(deckContent.sideboard);

          setMainDeck(main);
          setSideboard(side);

          const initialDeckText = deckToText(deckContent.mainDeck, deckContent.sideboard);
          setDeckText(initialDeckText);
          setVersions(getAllVersions([...main, ...side]) || []);
        } catch (err) {
          console.log(err)
          setError("El enlace ha expirado");
          navigate("/");
        }
      };
      fetchDeck();
    }
  }, [navigate, id]);

  return (
    <div>
    <header>
      {/* Incluir elementos del encabezado, como el logotipo y la navegación */}
    </header>
    <main>
    <div className="App">
      {error && <ErrorAlert message={error} />}
      <div className="autocomplete-input">
       <DeckNameInput deckName={deckName} setDeckName={setDeckName}/>
        <Legalities legalities={legalities} />
        <ShareButton isShared={isShared} handleShare={handleShare} deckText={deckText} />
        <ShareDCIButton handleShare={handleDCIList} deckText={deckText} version={version} />
        <div
          className="selected-card"
        >
          <img
            src={selectedCard || back_side_image}
            className="deck-image"
            alt={`Selected card`}
            itemProp="image"
          />
        </div>
        <CardInput  
          setDeckText={setDeckText}
          deckText={deckText}
          handleInput={handleInput}
          setSelectedCard={setSelectedCard}
        />
        <DeckInput
          deckText={deckText}
          setDeckText={setDeckText}
          handleInput={handleInput}
        />

      </div>
      <DeckDisplay 
        mainDeck={mainDeck}
        sideboard={sideboard}
        setSelectedCard={setSelectedCard} 
        versions={versions}
        setVersion={setVersion}
        version={version}
        />
    </div>
    </main>
    <footer>
      {/* Incluir elementos del pie de página, como información de contacto y enlaces adicionales */}
    </footer>
  </div>

  );
}

export default MagicApp;
