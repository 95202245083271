import React from 'react';
import styles from "./DeckDisplay.module.css";
import DeckSection from './DeckSection';
import RandomHand from './RandomHand';
import DeckSummary from "./DeckSummary";


function DeckDisplay({ mainDeck, sideboard, setSelectedCard, versions, setVersion, version }) {

  return (
    <div className={styles["deck-display"]}>
      <RandomHand
        mainDeck={mainDeck}
        setSelectedCard={setSelectedCard}
        version={version}
      />
      <DeckSection
        title="Main Deck"
        cards = {mainDeck}
        setSelectedCard={setSelectedCard}
        versions={versions}
        setVersion={setVersion}
        version={version}
      />
      <DeckSection
        title="Sideboard"
        cards = {sideboard}
        setSelectedCard={setSelectedCard}
        version={version}
        versions={versions}
        setVersion={setVersion}
      />
      <DeckSummary
        mainDeck = {mainDeck}
        sideboard={sideboard}
      />
    </div>
  );
}

export default DeckDisplay;
