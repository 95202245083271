import React from 'react';
import DeckImages from './DeckImages';
import styles from './DeckSection.module.css';

function DeckSection({ title, cards, setSelectedCard, versions, setVersion, version }) {
  return (
    <div>
      <div className={`${styles["tabs"]}`}>
        {versions.map((thisVersion) => (
            <button
              key={thisVersion}
              className={`${styles["tab"]} ${version === thisVersion ? styles["active"] : ''}`}
              onClick={() => setVersion(thisVersion)}
            >
              {thisVersion}
            </button>
          ))}
      </div> 
      <div className={`${styles["deck-section"]}`}>
        <DeckImages
          title={title}
          cards={cards}
          setSelectedCard={setSelectedCard}
          version={version}
        />
      </div>
    </div>
  );
}

export default DeckSection;
