// components/ShareButton.js
import React from 'react';
import styles from './ShareButton.module.css';

const ShareButton = ({ isShared, handleShare, deckText }) => {
  const handleClick = async () => {
    await handleShare(deckText);
  };

  return (
    <button onClick={handleClick} className={styles.shareButton}>
      {isShared ? 'Copied!' : 'Share'}
    </button>
  );
};

export default ShareButton;
