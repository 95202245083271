import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { backendUrl } from "../config";
import {
    FaEye,
    FaSkull,
    FaHeart,
    FaShieldAlt,
    FaPrayingHands,
    FaVial,
} from "react-icons/fa";
import Chart from "react-apexcharts";
import "./MasterApp.css";

function MasterApp() {
    const { gameName } = useParams();
    const navigate = useNavigate();
    const [gameData, setGameData] = useState({ players: [] });
    const [voting, setVoting] = useState({});
    const [roles, setRoles] = useState({});
    const [votingConfig, setVotingConfig] = useState({}); // { allowMultiple, showOption, type }
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [confirmAction, setConfirmAction] = useState(null);
    const [inLove, setInLove] = useState([]);
    const [protectedPlayers, setProtectedPlayer] = useState([]);
    const [sawPlayers, setSawPlayers] = useState([]);

    const [isVotingOpen, setIsVotingOpen] = useState(false);
    const [turns, setTurns] = useState([]);
    const [action, setAction] = useState(null);
    const [type, setSelectedRole] = useState("todos");

    const anyDead = gameData.players.some((player) => !player.alive);

    const sessionId = getSessionId();

    const votingStatus = (player) => {
        if (voting.open) {
            const selectedConfig = votingConfig[voting.type];
            if (selectedConfig.voters.includes(player.role)) {
                if (voting.votes[player.uuid]) {
                    return "voted";
                } else {
                    return "not-voted";
                }
            }
        }
        return "not-required";
    };

    const roleOptions = Object.keys(votingConfig || {}).filter((role) => {
        return gameData.players.some(
            (player) =>
                votingConfig[role].voters.includes(player.role) && player.alive
        );
    });

    const handlePlayerSelect = (player) => {
        switch (action) {
            case "kill":
                if (!player.alive) {
                    return;
                }
                setConfirmAction({
                    action: async () => handleKillPlayer(player.uuid),
                    message: `¿Estás seguro de querer matar a ${player.name}?`,
                });
                break;
            case "revive":
                if (player.alive) {
                    return;
                }
                setConfirmAction({
                    action: async () => handleRevivePlayer(player.uuid),
                    message: `¿Estás seguro de querer revivir a ${player.name}?`,
                });
                break;
            default:
                break;
        }
    };

    const handleDeselectPlayer = () => {
        setSelectedPlayer(null);
    };

    useEffect(() => {
        const fetchInLove = gameData.players
            .filter((player) => player.faillInLoveWith)
            .map((player) => player.uuid);

        const fetchProtected = gameData.players
            .filter((player) => player.isProtected)
            .map((player) => player.uuid);

        const fetchSaw = (
            gameData.players.find(
                (player) => player.role === "seer" && player.seenPlayers
            )?.seenPlayers || []
        ).map((player) => player.uuid);

        setInLove(fetchInLove);
        setProtectedPlayer(fetchProtected);
        setSawPlayers(fetchSaw);
    }, [gameData]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetch(`${backendUrl}/village/roles`);
                const data = await response.json();
                if (data.success) {
                    setRoles(data.roles);
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };

        const fetchTurns = async () => {
            try {
                const response = await fetch(`${backendUrl}/village/turns`);
                const data = await response.json();
                if (data.success) {
                    setTurns(data.turns);
                }
            } catch (error) {
                console.error("Error fetching turns:", error);
            }
        };

        const fetchVotingConfig = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/village/votingConfig`
                );
                const data = await response.json();
                if (data.success) {
                    setVotingConfig(data.votingConfig);
                }
            } catch (error) {
                console.error("Error fetching voting config:", error);
            }
        };

        fetchRoles();
        fetchTurns();
        fetchVotingConfig();
    }, []);

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/village/${gameName}/master/${sessionId}`
                );
                const data = await response.json();
                if (data.success) {
                    const gameResponse = await fetch(
                        `${backendUrl}/village/${gameName}`
                    );
                    const gameData = await gameResponse.json();
                    if (gameData.success) {
                        setGameData(gameData.game);
                    } else {
                        setErrorMessage(
                            "Error al cargar datos del juego: " + data.message
                        );
                    }
                } else {
                    setErrorMessage(
                        "Error al cargar datos del juego: " + data.message
                    );
                }
            } catch (error) {
                console.error("Error al cargar datos del juego:", error);
                setErrorMessage("Error al cargar datos del juego");
            }
        };

        fetchGameData();
        const intervalId = setInterval(fetchGameData, 5000);
        return () => clearInterval(intervalId);
    }, [gameName, sessionId]);

    useEffect(() => {
        const fetchVoting = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/village/${gameName}/voting`
                );
                const data = await response.json();
                if (data.success) {
                    setVoting(data.voting);
                    setIsVotingOpen(data.voting.open);
                }
            } catch (error) {
                console.error("Error al obtener votos:", error);
            }
        };

        fetchVoting();
        const votesIntervalId = setInterval(fetchVoting, 5000);
        return () => clearInterval(votesIntervalId);
    }, [gameName]);

    function getSessionId() {
        let sessionId = localStorage.getItem("sessionId");
        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem("sessionId", sessionId);
        }
        return sessionId;
    }

    async function handleKillPlayer(uuid) {
        try {
            const response = await fetch(
                `${backendUrl}/village/${gameName}/kill/${uuid}`
            );
            const data = await response.json();
            if (data.success) {
                const reloadResponse = await fetch(
                    `${backendUrl}/village/${gameName}`
                );
                const reloadData = await reloadResponse.json();
                if (reloadData.success) {
                    setGameData(reloadData.game);
                } else {
                    setErrorMessage(
                        "Error al cargar datos del juego: " + reloadData.message
                    );
                }
            } else {
                console.error("Error al matar al jugador:", data.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async function handleRevivePlayer(uuid) {
        try {
            const response = await fetch(
                `${backendUrl}/village/${gameName}/revive/${uuid}`
            );
            const data = await response.json();
            if (data.success) {
                const reloadResponse = await fetch(
                    `${backendUrl}/village/${gameName}`
                );
                const reloadData = await reloadResponse.json();
                if (reloadData.success) {
                    setGameData(reloadData.game);
                } else {
                    setErrorMessage(
                        "Error al cargar datos del juego: " + reloadData.message
                    );
                }
            } else {
                console.error("Error al matar al jugador:", data.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    function openVoting() {
        fetch(`${backendUrl}/village/${gameName}/openVoting`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ type }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success) {
                    console.error("Error al abrir votación:", data.message);
                } else {
                    setIsVotingOpen(true);
                }
            })
            .catch((error) => console.error("Error:", error));
    }

    function closeVoting() {
        fetch(`${backendUrl}/village/${gameName}/closeVoting`)
            .then((response) => response.json())
            .then((data) => {
                if (!data.success) {
                    console.error("Error al cerrar votación:", data.message);
                } else {
                    setIsVotingOpen(false);
                }
            })
            .catch((error) => console.error("Error:", error));
    }

    function resetGame() {
        // Mostrar confirmación para resetear el juego
        setConfirmAction({
            action: async () => {
                try {
                    const response = await fetch(
                        `${backendUrl}/village/${gameName}/reset`
                    );
                    const data = await response.json();
                    if (data.success) {
                        const reloadResponse = await fetch(
                            `${backendUrl}/village/${gameName}`
                        );
                        const reloadData = await reloadResponse.json();
                        if (reloadData.success) {
                            setGameData(reloadData.game);
                            setInLove([]);
                            setProtectedPlayer([]);
                            setSawPlayers([]);
                        } else {
                            setErrorMessage(
                                "Error al cargar datos del juego: " +
                                    reloadData.message
                            );
                        }
                    } else {
                        setErrorMessage(
                            "Error al cargar datos del juego: " + data.message
                        );
                    }
                } catch (error) {
                    setErrorMessage(
                        "Error al cargar datos del juego: " + error
                    );
                }
            },
            message: "¿Estás seguro de querer resetear el juego?",
        });
    }

    function newGame() {
        // Mostrar confirmación para resetear el juego
        setConfirmAction({
            action: () => navigate("/village/create"),
            message: "¿Estás seguro de querer crear un nuevo juego?",
        });
    }

    if (errorMessage) {
        return <div className='error'>{errorMessage}</div>;
    }

    if (selectedPlayer) {
        // Mostrar solo el jugador seleccionado en grande
        return (
            <div className='player-details' onClick={handleDeselectPlayer}>
                <h1 className='player-name-large'>{selectedPlayer.name}</h1>
                <p className='player-role-large'>
                    {roles[selectedPlayer.role].name}
                </p>
            </div>
        );
    }

    if (!selectedPlayer) {
        // Preparación de los datos para la gráfica de votos

        const countVotes = (votes) => {
            const voteCounts = {};

            if (!votes) {
                return voteCounts;
            }

            // Iterar sobre cada lista de votos
            Object.values(votes).forEach((votedPlayers) => {
                votedPlayers.forEach((playerId) => {
                    if (voteCounts[playerId]) {
                        voteCounts[playerId] += 1;
                    } else {
                        voteCounts[playerId] = 1;
                    }
                });
            });

            return voteCounts;
        };
        const voteCounts = countVotes(voting.votes);
        const chartData = {
            series: gameData.players.map(
                (player) => voteCounts[player.uuid] || 0
            ),
            options: {
                chart: {
                    type: "donut", // o 'pie' para un gráfico de torta
                },
                labels: gameData.players.map((player) => player.name),
            },
        };

        return (
            <div className='master-app'>
                {/* Botones de votación y reseteo */}
                <div className='controls'>
                    <button onClick={resetGame}>Reset</button>
                    <button className='create-game-button' onClick={newGame}>
                        New
                    </button>
                </div>
                <div className='game-turns'>
                    {turns
                        .filter((turn) =>
                            gameData.players.some(
                                (player) => player.role === turn && player.alive
                            )
                        )
                        .map((turn) => (
                            <div key={turn} className='turn-item'>
                                {roles[turn] ? roles[turn].name : turn}
                            </div>
                        ))}
                </div>
                {/* Cajetilla con iconos */}
                <div className='action-icons'>
                    <div
                        className={`kill-icon-container ${
                            action === "kill" ? "icon-selected" : ""
                        }`}
                        onClick={() =>
                            action === "kill"
                                ? setAction(null)
                                : setAction("kill")
                        }
                    >
                        <FaSkull className='kill-icon' />
                    </div>
                    {anyDead && (
                        <div
                            className={`kill-icon-container ${
                                action === "revive" ? "icon-selected" : ""
                            }`}
                            onClick={() =>
                                action === "revive"
                                    ? setAction(null)
                                    : setAction("revive")
                            }
                        >
                            <FaPrayingHands className='kill-icon' />
                        </div>
                    )}
                </div>
                {/* Listado de jugadores */}
                <div className='player-list'>
                    {gameData.players
                        .filter((p) => p.alive)
                        .map((player) => (
                            <div
                                key={player.uuid}
                                className={`player-item ${votingStatus(
                                    player
                                )}`}
                                onClick={() => handlePlayerSelect(player)} // Añadir evento de clic aquí
                            >
                                <span className='player-name'>
                                    {player.name}
                                </span>
                                <span className='player-role'>
                                    {roles[player.role].name}
                                </span>
                                <div className='player-icons'>
                                    {inLove.includes(player.uuid) && (
                                        <FaHeart className='player-icon' />
                                    )}
                                    {sawPlayers.includes(player.uuid) && (
                                        <FaEye className='player-icon' />
                                    )}
                                    {protectedPlayers.includes(player.uuid) && (
                                        <FaShieldAlt className='player-icon' />
                                    )}
                                    {player.deathPotionUsed && (
                                        <FaVial className='player-icon' />
                                    )}
                                </div>
                            </div>
                        ))}
                </div>

                {/* Separación visual */}
                {anyDead && <div className='separator'>Muertos</div>}

                {/* Listado de jugadores no vivos */}
                <div className='player-list dead-players'>
                    {gameData.players
                        .filter((p) => !p.alive)
                        .map((player) => (
                            <div
                                key={player.uuid}
                                className='player-item dead'
                                onClick={() => handlePlayerSelect(player)}
                            >
                                <span className='player-name'>
                                    {player.name}
                                </span>
                                <span className='player-role'>
                                    {roles[player.role].name}
                                </span>
                            </div>
                        ))}
                </div>

                {confirmAction && (
                    <div className='confirmation-dialog'>
                        <p>{confirmAction.message}</p>
                        <button
                            onClick={() => {
                                confirmAction.action();
                                setConfirmAction(null); // Limpiar confirmAction después de la acción
                            }}
                        >
                            Aceptar
                        </button>
                        <button onClick={() => setConfirmAction(null)}>
                            Cancelar
                        </button>
                    </div>
                )}

                {/* Botones de votación y reseteo */}
                <div className='controls'>
                    {isVotingOpen ? (
                        <button onClick={closeVoting}>Cerrar votación</button>
                    ) : (
                        <>
                            <select
                                value={type}
                                onChange={(e) =>
                                    setSelectedRole(e.target.value)
                                }
                            >
                                {roleOptions.map((role) => (
                                    <option key={role} value={role}>
                                        {roles[role]?.name || "Todos"}
                                    </option>
                                ))}
                            </select>
                            <button onClick={openVoting}>Abrir votación</button>
                        </>
                    )}
                </div>

                {/* Gráfico de votos */}
                <div className='votes-chart'>
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type='donut'
                    />
                </div>
            </div>
        );
    }
}

export default MasterApp;
