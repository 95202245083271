import axios from 'axios';

const API_BASE_URL = 'https://api.scryfall.com';

const cardDetailsCache = new Map();
const symbols = {};


export async function fetchCardImage(cardName) {
  const cardDetails = await fetchCardDetails(cardName);
  return cardDetails.imageUrl;
}

function getType(type) {
  if (type.includes("creature")) {
    return "creature";  
  }
  if (type.includes("land")) {
    return "land";
  }
  if (type.includes("planeswalker")) {
    return "planeswalker";
  }
return type;
}

export async function nameSuggestions(cardName) {
  const response = await axios.get(`${API_BASE_URL}/cards/search`, {
    params: {
      q: cardName,
      order: "usd",
    },
  });

  if (response.data.total_cards === 0) {
    return [];
  }

  return response.data.data.map((card) => card.name);
}

export async function fetchCardDetails(cardName) {
  if (cardName === 'total') {
    return {};
  }
  if (!cardName) {
    throw new Error('Card name is required');
  }

  if (cardDetailsCache.has(cardName)) {
    return cardDetailsCache.get(cardName);
  }

  try {
    const response = await axios.get(`${API_BASE_URL}/cards/named`, {
      params: {
        exact: cardName,
      },
    });
    const cardData = response.data;

    let imageUrl;
    let imageUrlXL;
    let manaCost;
  

    const multipleFaces = Array.isArray(cardData.card_faces) && cardData.card_faces.length > 0;

    if (multipleFaces) {
      imageUrl = cardData.card_faces[0].image_uris?.small || cardData.image_uris.small;
      imageUrlXL = cardData.card_faces[0].image_uris?.normal || cardData.image_uris.normal;
      manaCost = cardData.card_faces[0].mana_cost;

    } else {
      imageUrl = cardData.image_uris.small;
      imageUrlXL = cardData.image_uris.normal;
      manaCost = cardData.mana_cost;
    }

    manaCost = replacePlaceholders(manaCost);

    const cardDetails = {
      legalities: cardData.legalities,
      name: cardData.name,
      imageUrl: imageUrl,
      imageUrlXL: imageUrlXL,
      manaCost: manaCost,
      totalManaCost: cardData.cmc,
      isLand: cardData.type_line.toLowerCase().includes("land"),
      type: getType(cardData.type_line.toLowerCase())
    };

    cardDetailsCache.set(cardName, cardDetails);
    return cardDetails;
  } catch (error) {
    throw new Error('Card not found');
  }
}

async function fetchSymbols() {
  const response = await axios.get(`${API_BASE_URL}/symbology`);
  const data = response.data;
  
  for (const symbol of data.data) {
    symbols[symbol.symbol] = symbol.svg_uri;
  }
}

fetchSymbols();

function replacePlaceholders(description) {
  const regex = /{[^}]+}/g;
  const matches = description.match(regex);

  if (matches) {
    for (const match of matches) {
      const symbolUrl = symbols[match];
      if (symbolUrl) {
        description = description.replace(match, `\x1f${symbolUrl}\x1f`);
      }
    }
  }

  const parts = description.split('\x1f').map((part, index) => {
    return {
      type: index % 2 === 0 ? 'text' : 'symbol',
      content: part,
    };
  });

  return parts;
}
