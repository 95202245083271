// components/Legalities.js
import React from 'react';
import styles from './Legalities.module.css';
import { Tooltip } from 'react-tooltip';

const Legalities = ({ legalities }) => {
  return (
    <div className={styles.legalityContainer}>
      {[...legalities.entries()].map(([key, values]) => (
        <div
          key={key}
          className={`${styles.legalityItem} ${values.length > 0 ? styles.red : styles.green}`}
          data-tooltip-id="my-tooltip"
          data-tooltip-html={values.length > 0 ? "<div><ul><li>" + values.join('</li><li>') + "</li></ul></div>" : ''}
        >
          <button className={values.length > 0 ? styles.buttonRed : styles.buttonGreen}>
            {key}
          </button>
        </div>
      ))}
      <Tooltip id="my-tooltip" place="top" type="dark" effect="solid" multiline={true} />
    </div>
  );
};

export default Legalities;
