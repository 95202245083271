// ListSecretSantaApp.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Clipboard from 'clipboard';
import { useParams } from 'react-router-dom';
import './ListSecretSantaApp.css'; // Asegúrate de importar la hoja de estilo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { backendUrl, frontendUrl } from "./config";

const ListSecretSantaApp = () => {
  const [secretSantaData, setSecretSantaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAssigned, setShowAssigned] = useState(false);

  const { name: secretSantaName } = useParams();

  useEffect(() => {
    const fetchSecretSantaData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/get-secret-santa/${secretSantaName}`);
        setSecretSantaData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSecretSantaData();
  }, [secretSantaName]);

  useEffect(() => {
    // Configuramos Clipboard.js para los botones de copiar
    const clipboard = new Clipboard('.copy-button');

    clipboard.on('success', () => {
      console.log('Texto copiado al portapapeles');
    });

    clipboard.on('error', () => {
      console.error('Error al copiar al portapapeles');
    });

    // Limpiamos el evento al desmontar el componente
    return () => clipboard.destroy();
  }, []);

  const handleToggleAssigned = (participantId) => {
    setShowAssigned((prevShowAssigned) => ({
      ...prevShowAssigned,
      [participantId]: !prevShowAssigned[participantId],
    }));
  };
  const handleCopyUrl = (participantId) => {
    const url = `${frontendUrl}/secret-santa/${secretSantaName}/${participantId}`;
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!secretSantaData) {
    return <p>No data available</p>;
  }

  return (
    <div>
      <h1>Secret Santa List for {secretSantaName}</h1>
      <table>
        <thead>
          <tr>
            <th>Participant</th>
            <th>Excluded</th>
            <th>Assigned</th>
            <th>Copy URL</th>
            <th>Show/Hide Assigned</th>
          </tr>
        </thead>
        <tbody>
          {secretSantaData.participants.map((participant) => (
            <tr key={participant.id}>
              <td>{participant.name}</td>
              <td>{participant.exclude.join(', ')}</td>
              <td>{showAssigned[participant.id] ? participant.assigned : 'Secret'}</td>
              <td>
                <button
                  className="copy-button"
                  onClick={() => handleCopyUrl(participant.id)}
                  data-clipboard-text={`${frontendUrl}/secret-santa/${secretSantaName}/${participant.id}`}
                >
                  Copy
                </button>
              </td>
              <td>
                <button onClick={() => handleToggleAssigned(participant.id)}>
                    {showAssigned[participant.id] ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListSecretSantaApp;
