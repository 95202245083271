import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../config';
import './GamesApp.css';

function GamesApp() {
  const [games, setGames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${backendUrl}/village/games`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.games) {
          setGames(data.games);
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <div className="games-app">
      <div className="games-list">
        {games.map((game, index) => (
          <button
            key={index}
            className="game-button"
            onClick={() => navigate(`/village/${game}`)}
          >
            {game}
          </button>
        ))}
      </div>
      <button
        className="create-game-button"
        onClick={() => navigate('/village/create')}
      >
        Crear Partida
      </button>
    </div>
  );
}

export default GamesApp;
