import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { backendUrl } from "./config";


import './ShowSecretSantaApp.css'; // Agrega tu archivo CSS

const ShowSecretSantaApp = () => {
  const { name, id } = useParams();
  const [participantData, setParticipantData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/get-secret-santa/${name}/${id}`);
        setParticipantData(response.data.participant);
      } catch (error) {
        console.error('Error al obtener datos del participante:', error);
      }
    };

    fetchData();
  }, [name, id]);

  return (
    <div className="show-secret-santa-app">
      {participantData ? (
        <div className="message-container">
          <h1>Amigo Invisible</h1>
          <p>
            {participantData.name}, en este amigo invisible te ha tocado regalarle a {participantData.assigned}.
          </p>
          <div className="festive-icons">
            🎁 🎅 🌟
          </div>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default ShowSecretSantaApp;
