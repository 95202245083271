import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { backendUrl } from "../config";
import { FaHeart, FaSkull, FaEye, FaShieldAlt } from "react-icons/fa";
import Chart from "react-apexcharts";
import "./PlayerApp.css";

function PlayerApp() {
    const { gameName, playerId: playerUUID } = useParams();
    const navigate = useNavigate();
    const [player, setPlayer] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [players, setPlayers] = useState([]);
    const [roles, setRoles] = useState({});
    const [showRole, setShowRole] = useState(false);
    const [targetPlayers, setTargetPlayers] = useState([]); // [playerId, playerId
    const [votingConfig, setVotingConfig] = useState({}); //
    const [voting, setVoting] = useState({
        votes: {},
        open: false,
    });

    const SeenPlayersList = ({ seenPlayers }) => {
        return (
            <div className='seen-players'>
                {seenPlayers
                    .filter(({ uuid, role }) =>
                        players.find((p) => p.uuid === uuid && p.alive)
                    )
                    .map(({ uuid, role }) => {
                        const seenPlayer = players.find((p) => p.uuid === uuid);
                        return (
                            <div key={uuid} className='seen-player-item'>
                                <FaEye className='eye-icon' />
                                <span className='player-name'>
                                    {seenPlayer?.name}
                                </span>
                                <span className='player-role'>
                                    {roles[role].name}
                                </span>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const WolvesPlayerList = ({ wolves }) => {
        return (
            <div className='seen-players'>
                {wolves.map((uuid) => {
                    const seenPlayer = players.find((p) => p.uuid === uuid);
                    return (
                        <div key={uuid} className='seen-player-item'>
                            <span className='player-name'>
                                {seenPlayer?.name}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const LastKilledPlayerList = ({ deathPlayers }) => {
        return (
            <div className='seen-players'>
                {deathPlayers.map((uuid) => {
                    const seenPlayer = players.find((p) => p.uuid === uuid);
                    return (
                        <div key={uuid} className='seen-player-item'>
                            <FaSkull className='eye-icon' />
                            <span className='player-name'>
                                {seenPlayer?.name}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const ProtectedPlayerList = ({ protectedPlayers }) => {
        return (
            <div className='seen-players'>
                {protectedPlayers.map((uuid) => {
                    const seenPlayer = players.find((p) => p.uuid === uuid);
                    return (
                        <div key={uuid} className='seen-player-item'>
                            <FaShieldAlt className='eye-icon' />
                            <span className='player-name'>
                                {seenPlayer?.name}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const InLoveWith = ({ faillInLoveWith }) => {
        return (
            <div className='seen-players'>
                {[faillInLoveWith].map((uuid) => {
                    const seenPlayer = players.find(
                        (p) => p.uuid === faillInLoveWith
                    );
                    return (
                        <div key={uuid} className='seen-player-item'>
                            <FaHeart className='eye-icon' />
                            <span className='player-name'>
                                {seenPlayer?.name}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const getSessionId = () => {
        let sessionId = localStorage.getItem("sessionId");
        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem("sessionId", sessionId);
        }
        return sessionId;
    };

    useEffect(() => {
        const fetchVotingConfig = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/village/votingConfig`
                );
                const data = await response.json();
                if (data.success) {
                    setVotingConfig(data.votingConfig);
                }
            } catch (error) {
                console.error("Error fetching voting config:", error);
            }
        };

        fetchVotingConfig();
    }, []);

    useEffect(() => {
        const allowVoting = (voting) => {
            if (!voting.open) {
                return false;
            }

            const selectedConfig = votingConfig[voting?.type];

            if (!selectedConfig || !player) {
                return false;
            }

            return selectedConfig.voters.includes(player.role);
        };

        const updateTardePlayersList = (voting) => {
            if (!voting.open) {
                setTargetPlayers([]);
                return;
            }

            if (!allowVoting(voting)) {
                setTargetPlayers([]);
                return;
            }

            const selectedConfig = votingConfig[voting?.type];

            if (!selectedConfig) {
                setTargetPlayers([]);
                return;
            }

            const excludePlayers = [];

            if (!selectedConfig.allowSelfTarget) {
                excludePlayers.push(player.uuid);
            }

            if (!selectedConfig.allowTargetInLove && player.faillInLoveWith) {
                excludePlayers.push(player.faillInLoveWith);
            }

            if (voting.type === "wolf") {
                for (const uuid of player.wolves || []) {
                    excludePlayers.push(uuid);
                }
            }

            if (voting.type === "protector") {
                for (const uuid of player.protectedPlayers || []) {
                    excludePlayers.push(uuid);
                }
            }

            if (voting.type === "seer") {
                for (const { uuid } of player.seenPlayers || []) {
                    excludePlayers.push(uuid);
                }
            }

            let targets = [];

            if (voting.type === "whiteWolf") {
                targets = player.wolves
                    .map((w) => players.find((p) => w === p.uuid))
                    .filter((wolf) => !excludePlayers.includes(wolf.uuid));
            } else {
                targets = players.filter(
                    (p) => !excludePlayers.includes(p.uuid)
                );
            }

            // Exclude non alive players
            targets = targets.filter((p) => p.alive);

            setTargetPlayers(targets);
        };

        const fetchVoting = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/village/${gameName}/voting`
                );
                const data = await response.json();
                if (data.success) {
                    setVoting(data.voting);
                    updateTardePlayersList(data.voting);
                }
            } catch (error) {
                console.error("Error al obtener votos:", error);
            }
        };

        fetchVoting();
        const votesIntervalId = setInterval(fetchVoting, 5000);
        return () => clearInterval(votesIntervalId);
    }, [gameName, player, players, votingConfig]);

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/village/${gameName}/players`
                );
                const data = await response.json();
                if (data.success) {
                    setPlayers(data.players);
                }
            } catch (error) {
                console.error("Error fetching  players:", error);
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await fetch(`${backendUrl}/village/roles`);
                const data = await response.json();
                if (data.success) {
                    setRoles(data.roles);
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };

        fetchPlayers();
        fetchRoles();

        const intervalId = setInterval(fetchPlayers, 5000);

        return () => clearInterval(intervalId);
    }, [gameName, playerUUID]);

    useEffect(() => {
        const fetchPlayerData = async () => {
            try {
                const sessionId = getSessionId();
                const response = await fetch(
                    `${backendUrl}/village/${gameName}/${playerUUID}/${sessionId}`
                );
                const data = await response.json();
                if (data.success) {
                    setPlayer(data.player);
                } else {
                    setErrorMessage(data.message);
                }
            } catch (error) {
                console.error("Error fetching player data:", error);
                setErrorMessage("Error al cargar los datos del jugador");
            }
        };

        fetchPlayerData(); // Llama la función inmediatamente al cargar el componente

        const intervalId = setInterval(fetchPlayerData, 5000); // Llama la función cada 10 segundos

        return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
    }, [gameName, playerUUID]);

    const handleVote = async (votedPlayerId) => {
        const payload = {
            playerId: playerUUID,
            votedPlayerId,
        };

        try {
            const response = await fetch(
                `${backendUrl}/village/${gameName}/vote`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload),
                }
            );

            const data = await response.json();
            // Handle the response data here
            if (data.success) {
                const response = await fetch(
                    `${backendUrl}/village/${gameName}/voting`
                );
                const data = await response.json();
                if (data.success) {
                    setVoting(data.voting);
                }
            } else {
                console.error("Error al votar:", data.message);
            }
        } catch (error) {
            console.error("Error voting:", error);
        }
    };

    if (errorMessage) {
        return (
            <div className='player-app'>
                <div className='error-message'>
                    <p>{errorMessage}</p>
                    <button onClick={() => navigate(`/village/${gameName}`)}>
                        Volver
                    </button>
                </div>
            </div>
        );
    }

    if (!player || !player.alive) {
        return (
            <div className='player-app'>
                <div className='dead-message'>
                    <FaSkull />
                    <p>RIP</p>
                </div>
            </div>
        );
    }
    const inLove = player && player.faillInLove;
    const lovedOne = inLove
        ? players.find((p) => p.uuid === player.faillInLoveWith)
        : null;

    const countVotes = (votes) => {
        const voteCounts = {};

        if (!votes) {
            return voteCounts;
        }

        // Iterar sobre cada lista de votos
        Object.values(votes).forEach((votedPlayers) => {
            votedPlayers.forEach((playerId) => {
                if (voteCounts[playerId]) {
                    voteCounts[playerId] += 1;
                } else {
                    voteCounts[playerId] = 1;
                }
            });
        });

        return voteCounts;
    };
    const voteCounts = countVotes(voting.votes);
    const chartData = {
        series: players.map((player) => voteCounts[player.uuid] || 0),
        options: {
            chart: {
                type: "donut", // o 'pie' para un gráfico de torta
            },
            labels: players.map((player) => player.name),
        },
    };

    return (
        <div className='player-app'>
            {/* Nombre del jugador */}
            <div className='player-name-header'>
                <h1>{player?.name}</h1>
            </div>
            <>
                <div
                    className={`role-info ${showRole ? "visible" : ""}`}
                    onClick={() => setShowRole(!showRole)}
                >
                    <div className='content'>
                        <h2>
                            {showRole
                                ? player && roles[player.role]?.name
                                : "Role"}
                        </h2>
                        <p>
                            {showRole
                                ? player && roles[player.role]?.description
                                : "Buena suerte intentando leer esto crack!"}
                        </p>
                        {showRole && inLove && (
                            <div className='love-indicator'>
                                <FaHeart />
                                <span>{lovedOne?.name}</span>
                            </div>
                        )}
                        {showRole && player?.seenPlayers && (
                            <SeenPlayersList seenPlayers={player.seenPlayers} />
                        )}
                        {showRole && player?.wolves && (
                            <WolvesPlayerList wolves={player.wolves} />
                        )}
                        {showRole && player?.deathPlayers && (
                            <LastKilledPlayerList
                                deathPlayers={player.deathPlayers}
                            />
                        )}
                        {showRole && player?.protectedPlayers && (
                            <ProtectedPlayerList
                                protectedPlayers={player.protectedPlayers}
                            />
                        )}
                        {showRole && player?.faillInLoveWith && (
                            <InLoveWith
                                faillInLoveWith={player.faillInLoveWith}
                            />
                        )}
                    </div>
                </div>

                {targetPlayers.length > 0 &&
                    votingConfig[voting?.type]?.description && (
                        <div className='voting-description'>
                            <p>{votingConfig[voting?.type].description}</p>
                        </div>
                    )}
                <div className='players-list'>
                    {targetPlayers.map((p) => {
                        const isVotedByMe = voting.votes[playerUUID]?.includes(
                            p.uuid
                        );

                        return (
                            <div
                                key={p.uuid}
                                onClick={() => handleVote(p.uuid)}
                                className={`player-item ${
                                    isVotedByMe ? "voted" : ""
                                }`}
                            >
                                <span className='player-name'>{p.name}</span>
                            </div>
                        );
                    })}
                </div>
                {targetPlayers.length > 0 &&
                    votingConfig[voting?.type]?.display && (
                        <div className='votes-chart'>
                            <Chart
                                options={chartData.options}
                                series={chartData.series}
                                type='donut'
                            />
                        </div>
                    )}
            </>
        </div>
    );
}

export default PlayerApp;
