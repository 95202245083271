// components/CardInput.js

import styles from "./DeckNameInput.module.css";
import {toSlug} from "../utils/strings";

function DeckNameInput({ deckName, setDeckName }) {

    const handleDeckNameChange = (event) => {
        setDeckName(toSlug(event?.target?.value));
     };
  

  return (
    <div className={styles["deckName"]}>
      <input
          type="text"
          placeholder="deck name..."
          name="deckName"
          className={styles["deckName-input"]}
          value={deckName}  
          onChange={handleDeckNameChange}
        />
    </div>
  );
}

export default DeckNameInput;