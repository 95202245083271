import axios from 'axios';
import slugify  from 'slugify'


export function isMtgdeck(deckText) {
    return deckText.includes('https://mtgdecks.net') || deckText.includes('https://www.mtgdecks.net')
  }
  
 export function isGoldfish(deckText) {
    return deckText.includes('https://mtggoldfish.com') || deckText.includes('https://www.mtggoldfish.com')
  }
  
 export async function  parseMtgdeck(setDeckContent, url) {
      const response = await axios.get(url)
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');
      const arenaDeckElement = doc.getElementById('arena_deck');
      if (arenaDeckElement) {
        setDeckContent(arenaDeckElement.textContent);
        return arenaDeckElement.textContent;
      }
      setDeckContent(null);
      return '';
  }
  
 export function goldFishIncludeID(deckText) {
    const parts = deckText.split('/');
    const lastPart = parts[parts.length - 1];
    const id = lastPart.split('#')[0];
    // if id is a number
    if (!isNaN(id)) {
      return id;
    }
    return null;
  }
  
 export async function tryToGetIDForGoldfish(deckText) {
    const response = await axios.get(deckText)
    const parser = new DOMParser();
    const doc = parser.parseFromString(response.data, 'text/html');
    
    // Busca elementos con la clase "deck-view-purchase-button"
    const purchaseButtons = doc.getElementsByClassName('deck-view-purchase-button');
    if (purchaseButtons.length > 0) {
      // Accede al primer elemento encontrado
      const firstPurchaseButton = purchaseButtons[0];
  
      // Obtiene el valor del atributo data-value
      const id = firstPurchaseButton.getAttribute('data-value');
      if (!isNaN(id)) {
        return id;
      }
    }
    return null;
  }
  
  
 export async function parseGoldfish(setDeckContent, deckText) {
  
    let id = goldFishIncludeID(deckText);
    if (!id) {
      id = await tryToGetIDForGoldfish(deckText);
    }
  
    if (!id) {
      setDeckContent(null);
      return '';
    }
  
  
    const response = await axios.get(`https://www.mtggoldfish.com/deck/arena_download/${id}`)
    const parser = new DOMParser();
    const doc = parser.parseFromString(response.data, 'text/html');
    
    // Busca elementos con la clase "copy-paste-box"
    const copyPasteBoxes = doc.getElementsByClassName('copy-paste-box');
    if (copyPasteBoxes.length > 0) {
      // Utiliza el primer elemento encontrado
      const firstCopyPasteBox = copyPasteBoxes[0];
      if (firstCopyPasteBox) {
        setDeckContent(firstCopyPasteBox.textContent);
        return firstCopyPasteBox.textContent;
      }
      setDeckContent(null);
      return '';
    }
  }
  
  export function openMKM(name, amount) {
    // Reemplaza 'URL_DEL_SITIO_EXTERN' con la URL del sitio externo y agrega el nombre como parámetro
    const externalUrl = `https://www.cardmarket.com/en/Magic/Cards/${slugify(name, {
      replacement: '-',  
      lower: true,      
      remove: /[,'/]/g 
    })}?&sellerCountry=10&language=1,4&minCondition=2&amount=${amount}`;
    window.open(externalUrl, '_blank'); // Abre la URL en una nueva pestaña o ventana
  };
