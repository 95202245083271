// App.js
import React from "react";
import {Route, Routes } from "react-router-dom";
import MagicApp from "./MagicApp";
import CreateSecretSantaApp from "./CreateSecretSantaApp";
import ShowSecretSantaApp from "./ShowSecretSantaApp";
import ListSecretSantaApp from "./ListSecretSantaApp";
import CreateApp from "./village/CreateApp";
import GamesApp from "./village/GamesApp";
import SelectPlayersApp from "./village/SelectPlayersApp";
import PlayerApp from "./village/PlayerApp";
import MasterApp from "./village/MasterApp";

function App() {
  return (
      <Routes>
        <Route path="/" element={<MagicApp />} />
        <Route path="/magic/:id" element={<MagicApp />} />
        <Route path="/secret-santa" element={<CreateSecretSantaApp />} />
        <Route path="/secret-santa/god/:name" element={<ListSecretSantaApp />} />
        <Route path="/secret-santa/:name/:id" element={<ShowSecretSantaApp />} />
        <Route path="/village" element={<GamesApp />}/>
        <Route path="/village/create" element={<CreateApp />}/>
        <Route path="/village/:gameName" element={<SelectPlayersApp />}/>
        <Route path="/village/:gameName/master" element={<MasterApp />}/>
        <Route path="/village/:gameName/:playerId" element={<PlayerApp />}/>

      </Routes>
  );
}

export default App;
