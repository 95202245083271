import React, { useState } from 'react';
import styles from './DeckImages.module.css';
import image from '../assets/back_side.bmp';
import {openMKM} from '../utils/integrations'
import {getAmount} from '../utils/versions'


function DeckImages({ title, cards, setSelectedCard, version }) {

  const [expandedCard, setExpandedCard] = useState(null);

  const handleClick = (cardName) => {
    // Only on mobile
    if (window.innerWidth <= 768 ) {
      if (expandedCard === cardName) {
        setExpandedCard(null);
      } else {
        setExpandedCard(cardName);
      }
    }
  };

  const countCards = (cards, version) => cards.reduce( (acc, card) => {
    return acc += getAmount(card, version)
  }, 0)

  const handleMouseEnter = (src) => () => {
    if (window.innerWidth > 768 ) {
      setSelectedCard(src);
    }
  };
  
  return (
    <div className={`${styles["deck-images"]}`}>
      {cards.filter((card) => getAmount(card, version) > 0)
      .map((card) => (
        <div
          key={`title ${card.cardName}`}
          className={`${styles["image-wrapper"]} ${expandedCard === card.cardName ? styles.expanded : ''}`}
          onClick={() => handleClick(card.cardName)}
          onClickOut={() => handleClick}
          onMouseEnter={handleMouseEnter(card.imageUrlXL)}
          onDoubleClick={() => openMKM(card.name, card.count)}
          itemScope
          itemType="http://schema.org/Product"
        >
          <div className={`${styles["card-image"]}`}>
            <img
              src={expandedCard === card.cardName ? card.imageUrlXL : card.imageUrl}
              alt={`Imagen de la carta de Magic: The Gathering llamada ${card.cardName}`}
              itemProp="image"
            />
          </div>
          <div className={`${styles["card-quantity"]}`}>
            {getAmount(card, version)}
          </div>
          <meta itemProp="name" content={card.name} />
          <meta itemProp="description" content={`Carta de Magic: The Gathering llamada ${card.cardName}`} />
        </div>
      ))}
          <div
          key="total"
          onClick={() => handleClick("total")}
          onClickOut={() => handleClick()}
          className={`${styles["image-wrapper"]} ${expandedCard === "total" ? styles.expanded : ''}`}
        >
          <div className={`${styles["card-image"]}`}>
            <img
              src={image}
              alt={`Number of cards`}
              itemProp="image"
            />
          </div>
          <div className={`${styles["card-quantity-total"]}`}>
            {countCards(cards, version)}
          </div>
          <meta itemProp="description" content={`Number total cards`} />
        </div>
    </div>
  );
}

export default DeckImages;
