// components/DeckInput.js

import React, { useState, useEffect } from "react";
import styles from "./DeckInput.module.css";

function DeckInput({ deckText, setDeckText, handleInput }) {
  const [inputValue, setInputValue] = useState(deckText || "");

  useEffect(() => {
    setInputValue(deckText);
  }, [deckText]);

  useEffect(() => {
    if (inputValue) {
      setDeckText(inputValue);
    }
  }, [inputValue, setDeckText]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    handleInput(event.target.value);
  };

  return (
    <div className={styles.deckInputContainer}>
      <textarea
        placeholder="Paste your deck here for intance:
        4 Fabled Passage
        3 Forest C{comment for this card}
        2 Plains V{base: 0, version2}
        You can also paste a deck from mtgdecks.net just pasting the url of the deck
        "
        value={inputValue}
        onChange={handleChange}
        rows={50}
      />
    </div>
  );
}

export default DeckInput;