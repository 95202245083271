import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { backendUrl } from "../config";
import "./SelectPlayersApp.css";

function SelectPlayersApp() {
    const [players, setPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const { gameName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${backendUrl}/village/${gameName}/players`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setPlayers(data.players);
                }
            })
            .catch((error) => console.error("Error:", error));
    }, [gameName]);

    const confirmSelection = (player) => {
        setSelectedPlayer(player);
    };

    const navigateToPlayer = () => {
        if (selectedPlayer) {
            const uuid =
                selectedPlayer.name === "Master"
                    ? "master"
                    : selectedPlayer.uuid;

            navigate(`/village/${gameName}/${uuid}`);
        }
    };

    return (
        <div className='select-players'>
            {players.map((player) => (
                <button
                    className='player-button'
                    key={player.uuid}
                    onClick={() => confirmSelection(player)}
                >
                    {player.name}
                </button>
            ))}
            <div className='special-buttons'>
                <button
                    onClick={() => confirmSelection({ name: "Master" })}
                    className='master-button'
                >
                    Master
                </button>
                <button
                    onClick={() => navigate("/village")}
                    className='back-button'
                >
                    Volver a lista de partidas
                </button>
            </div>

            {selectedPlayer && (
                <div className='confirmation-dialog'>
                    <p>¿Estás seguro de que eres {selectedPlayer.name}?</p>
                    <button
                        className='accept-button'
                        onClick={navigateToPlayer}
                    >
                        Aceptar
                    </button>
                    <button
                        className='cancel-button'
                        onClick={() => setSelectedPlayer(null)}
                    >
                        Cancelar
                    </button>
                </div>
            )}
        </div>
    );
}

export default SelectPlayersApp;
