import React, { useState, useEffect } from 'react';
import styles from './RandomHand.module.css';
import stylesDecck from './DeckImages.module.css';
import { getAmount } from '../utils/versions';

function RandomHand({ mainDeck, setSelectedCard, version }) {
  const [expandedCard, setExpandedCard] = useState(null);

  const [randomHand, setRandomHand] = useState([]);
  const [deck, setDeck] = useState([]); 

  useEffect(() => {
    setRandomHand(randomHand);
  }, [randomHand]);


  const generateRandomHand = () => {
    if (mainDeck.length === 0) return;

    const hand = [];
    const deck = [];

    mainDeck.filter((card) => getAmount(card, version) > 0).forEach((card) => {
      for (let i = 0; i < getAmount(card, version); i++) {
        const cardImage = mainDeck.find((img) => img.cardName === card.cardName);
        deck.push({ ...card, imageUrl: cardImage.imageUrl });
      }
    });

    for (let i = 0; i < 7 && deck.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * deck.length);
      hand.push(deck[randomIndex]);
      deck.splice(randomIndex, 1); 
    }
    setDeck(deck);
    setRandomHand(hand);
  };

  const addExtraCard = () => {
      const currentDeck = [...deck];
      const currentHand = [...randomHand];
      const randomIndex = Math.floor(Math.random() * currentDeck.length);
      currentHand.push(currentDeck[randomIndex]);
      currentDeck.splice(randomIndex, 1); 

      setDeck(currentDeck);
      setRandomHand(currentHand);
  }

  const handleClick = (cardName) => {
    if (expandedCard === cardName) {
      setExpandedCard(null);
    } else {
      setExpandedCard(cardName);
    }
  };

  const handleOnMouseOver = (imgURL) => {
    setSelectedCard(imgURL);
  }

  return (
    <div className={styles["deck-section"]}>
      <button
        className={styles["generate-hand-button"]}
        onClick={generateRandomHand}
        disabled={mainDeck.length === 0}
      >
       Draw a hand
      </button>
      
      <button
        className={styles["add-one-card"]}
        onClick={addExtraCard}
        disabled={mainDeck.length === 0 || deck.length === 0}
      >
        Draw +1
      </button>
      <div className={`${styles["random-hand-images"]}`}>
        {randomHand.map((card, index) => (
            <div
            key={index}
            className={`${stylesDecck["image-wrapper"]} ${expandedCard === card.cardName ? stylesDecck.expanded : ''}`}
            onClick={() => handleClick(card.cardName)}
            onMouseOver={() => handleOnMouseOver(card.imageUrlXL)}
            itemScope
            itemType="http://schema.org/Product"
          >
            <div key={index} className={`${stylesDecck["card-image"]}`}>
              {card.imageUrl && <img src={card.imageUrl} alt={card.cardName} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RandomHand;
