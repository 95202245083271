// components/CardInput.js

import React, { useState }  from "react";
import styles from "./CardInput.module.css";
import { nameSuggestions, fetchCardDetails  } from '../services/cardService';

function CardInput({ deckText, setDeckText, handleInput, setSelectedCard }) {

    const [text, setText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [count, setCount] = useState(4);

    const handleChange = async (e) => {
      const { value } = e.target;
      setText(value);

      if (value.length <= 3) {
        setSuggestions([]);
        return;
      }

      const suggestions = await nameSuggestions(value);

      if (suggestions.length === 0) {
        setSuggestions([]);
        return;
      }
      setSuggestions(suggestions.slice(0, 20));
    };
  
    const handleSuggestionClick = (suggestion) => {
      setText(suggestion);
      setSuggestions([]);
    };

    const handleCounterChange = (e) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value) && value >= 1 && value <= 99) {
          setCount(value);
        }
      };

    const handleAddClick = () => {
        const cardText = `${count} ${text}`;
        setText("");
        setCount(4);
        const updatedDeck = deckText ? `${cardText}\n${deckText}` : cardText
        setDeckText(updatedDeck);
        handleInput(updatedDeck)
    };

    const handleOnMouseOver = async (e) => {
      const { innerText: cardName } = e.target;
      const details = await fetchCardDetails(cardName);
      setSelectedCard(details.imageUrlXL);
    }

  const isButtonDisabled = text === '';

  return (
    <div className={styles["autocompletewithcounter"]}>
      <input
        type="number"
        value={count}
        min={1}
        max={99}
        onChange={handleCounterChange}
        className={styles["counter-input"]}
      />
      <input
        type="text"
        placeholder="Add a card..."
        value={text}
        onChange={handleChange}
        className={styles["autocomplete-input"]}
      />
    <button
     className={styles["add-button"]} 
     onClick={handleAddClick}
     disabled={isButtonDisabled}
     >
        add
      </button>
      <ul className={styles["suggestions"]}>
        {suggestions.map((suggestion) => (
          <li key={suggestion} onClick={() => handleSuggestionClick(suggestion)} onMouseOver={handleOnMouseOver}> 
            {suggestion}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CardInput;