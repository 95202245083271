import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../config';
import './CreateApp.css';

function CreateApp() {

  const navigate = useNavigate();

  const [nameList, setNameList] = useState([]);
  const [listName, setListName] = useState('');
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);


  useEffect(() => {
    fetch(`${backendUrl}/village/names`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.names) {
          const formattedOptions = data.names.map(name => ({ value: name, label: name }));
          setOptions(formattedOptions);
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const handleCreate = inputValue => {
    if (inputValue && !nameList.includes(inputValue)) {
      setNameList([...nameList, inputValue]);
    }
  };
  const handleDeleteName = nameToDelete => {
    setNameList(nameList.filter(name => name !== nameToDelete));
  };

  const handleSendList = () => {
    if (nameList.length < 9 || !listName) {
      // Mostrar algún mensaje de error o indicación visual
      return;
    }

    const payload = {
      name: listName,
      players: nameList.map(name => ({ name }))
    };

    fetch(`${backendUrl}/village/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setIsSubmitted(true);
      } else {
        // Manejar el caso de error
      }
    })
    .catch(error => console.error('Error:', error));
  };


  if (isSubmitted) {
    return (
      <div className="submission-success">
        <p>¡La partida ha sido creada con éxito!</p>
        <button onClick={() => navigate('/village')}>
          Ver partidas
        </button>
      </div>
    );
  }


  const counterStyle = nameList.length < 9 ? { backgroundColor: 'red' } : { backgroundColor: 'lightgreen' };


  return (
    <div className="village-app">
      <div className="player-counter" style={counterStyle}>
        {nameList.length}
      </div>
      <input
        type="text"
        className="list-name-input"
        placeholder="Nombre de la lista"
        value={listName}
        onChange={e => setListName(e.target.value)}
      />
      <CreatableSelect
        isClearable
        isSearchable
        options={options}
        onChange={(option) => option && handleCreate(option.value)}
        onInputChange={value => setInputValue(value)}
        value={inputValue}
        placeholder="Escribe o selecciona un nombre"
      />
      <div className="name-list">
        {nameList.map((name, index) => (
          <div key={index} className="name-item">
            {name}
            <button onClick={() => handleDeleteName(name)}>Eliminar</button>
          </div>
        ))}
      </div>
      <button className={`send-list-button ${nameList.length < 9 || !listName ? 'disabled' : ''}`} onClick={handleSendList}>
        Enviar Lista
      </button>
    </div>
  );
}

export default CreateApp;
