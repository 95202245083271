import React from 'react';
import styles from './DeckSummary.module.css';

function DeckSummary({ mainDeck, sideboard }) {
  const countTypes = (deck) => {
    const counts = {};
    deck.forEach(card => {
      counts[card.type] = (counts[card.type] || 0) + card.count;
    });
    return counts;
  };

  const countCosts = (deck) => {
    const counts = {};
    deck.forEach(card => {
      if (card.totalManaCost) {
        counts[card.totalManaCost] = (counts[card.totalManaCost] || 0) + card.count;
      }
    });
    return counts;
  };

  const getTotal = (deck) => {
    let count = 0;
    deck.forEach(card => {
        count += card.count;
    });
    return count;
  }

  const mainDeckTypesCounts = countTypes(mainDeck);
  const sideboardTypesCounts = countTypes(sideboard);
  const mainDeckCostsCounts = countCosts(mainDeck);
  const sideboardCostsCounts = countCosts(sideboard);
  const totalMain = getTotal(mainDeck);
  const totalSideboard = getTotal(sideboard);

  return (
    <div className={styles.deckDisplayContainer}>
      <div className={styles.deckDisplay}>
        <h2>Main Deck: {totalMain}</h2>
        <div className={styles.deckSection}>
          <div className={styles.cardType}>
            {Object.entries(mainDeckTypesCounts).map(([type, count]) => (
              <p key={`type-${type}`}>{type}: {count}</p>
            ))}
          </div>
          <div className={styles.cardCost}>
            {Object.entries(mainDeckCostsCounts).map(([cost, count]) => (
              <p key={`cost-${cost}`}>
                <img className={styles.cardImage} src={`https://svgs.scryfall.io/card-symbols/${cost}.svg`} alt={`${cost} symbol`} />: {count}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.deckDisplay}>
        <h2>Sideboard: {totalSideboard}</h2>
        <div className={styles.deckSection}>
          <div className={styles.cardType}>
            {Object.entries(sideboardTypesCounts).map(([type, count]) => (
              <p key={`type-${type}`}>{type}: {count}</p>
            ))}
          </div>
          <div className={styles.cardCost}>
            {Object.entries(sideboardCostsCounts).map(([cost, count]) => (
              <p key={`cost-${cost}`}>
                <img className={styles.cardImage} src={`https://svgs.scryfall.io/card-symbols/${cost}.svg`} alt={`${cost} symbol`} />: {count}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeckSummary;
