export const defaultVersion = 'base';

export function getAllVersions(cards) {
    const versions = new Set()
    versions.add(defaultVersion)
    for (const card of cards) {
        if (card.versions !== undefined) {
            ( Object.keys(card.versions) || []).forEach(key => versions.add(key))
        }
    }
    return Array.from(versions);
}


export function getAmount(card, version) {
    return card.versions !== undefined && card.versions[version] !== undefined ? card.versions[version] : card.count
}