import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { backendUrl } from "./config";

import './CreateSecretSantaApp.css'; // Asegúrate de importar tu archivo CSS

const CreateSecretSantaApp = () => {
  const [secretSantaName, setSecretSantaName] = useState('');
  const [participants, setParticipants] = useState([{ name: '', exclude: [] }]);
  const [loading, setLoading] = useState(false);

  const handleAddParticipant = () => {
    setParticipants([...participants, { name: '', exclude: [] }]);
  };

  const handleParticipantNameChange = (index, value) => {
    const updatedParticipants = [...participants];
    updatedParticipants[index].name = value;
    setParticipants(updatedParticipants);
  };

  const handleExcludeChange = (participantIndex, selectedOptions) => {
    const updatedParticipants = [...participants];
    updatedParticipants[participantIndex].exclude = selectedOptions.map(option => option.value);
    setParticipants(updatedParticipants);
  };

  const handleCreateSecretSanta = async () => {
    try {
      setLoading(true);

      const requestData = {
        name: secretSantaName,
        participants: participants.filter((participant) => participant.name !== '' && participant.name.trim() !== ''),
      };

    // Realiza la petición a la API local
    const response = await axios.post(`${backendUrl}/generate-secret-santa`, requestData);

    console.log(response.data);

    } catch (error) {
      console.error('Error al crear el Secret Santa:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="secret-santa-app">
      <h1>Create Secret Santa</h1>

      <div className="participants-section">
        <h2>Participants:</h2>

        {participants.map((participant, index) => (
          <div key={index} className="participant">
            <div className="input-container">
              <label htmlFor={`participantName${index}`}>Name:</label>
              <input
                type="text"
                id={`participantName${index}`}
                value={participant.name}
                onChange={(e) => handleParticipantNameChange(index, e.target.value)}
              />
            </div>

            <div className="exclusions-container">
              <label htmlFor={`exclusions${index}`}>Exclusions:</label>
              <Select
                id={`exclusions${index}`}
                isMulti
                value={participants[index].exclude.map(name => ({ value: name, label: name }))}
                options={participants.map(otherParticipant => ({ value: otherParticipant.name, label: otherParticipant.name }))}
                onChange={(selectedOptions) => handleExcludeChange(index, selectedOptions)}
              />
            </div>
          </div>
        ))}

        <button onClick={handleAddParticipant}>Add Participant</button>
      </div>

      <div className="input-section">
        <label htmlFor="secretSantaName">Secret Santa Name:</label>
        <input
          type="text"
          id="secretSantaName"
          value={secretSantaName}
          onChange={(e) => setSecretSantaName(e.target.value)}
        />
      </div>

      <button onClick={handleCreateSecretSanta} disabled={loading}>
        {loading ? 'Creating Secret Santa...' : 'Create Secret Santa'}
      </button>
    </div>
  );
};

export default CreateSecretSantaApp;
